import { useSelector } from 'react-redux';
import loadable from '@utils/loadable';
import classNames from 'classnames/bind';

import withComponentName from '../../decorators/withComponentName';
import withMetatag from '../../decorators/metadataDecorators/metatagsTitleDecorator';

import Styles from './styles.module.scss';

const MeetupTopSection = loadable(() => import('./MeetupComponents/MeetupTopSection'));
const MeetupUpcomingEventsSection = loadable(() => import('./MeetupComponents/MeetupUpcomingEventsSection'));
const MeetupSocialSection = loadable(() => import('./MeetupComponents/MeetupSocialSection'));
const MeetupVisitSection = loadable(() => import('./MeetupComponents/MeetupVisitSection'));
const MeetupFormSection = loadable(() => import('./MeetupComponents/MeetupFormSection'));
const MeetupPreviousSection = loadable(() => import('./MeetupComponents/MeetupPreviousSection'));

const cx = classNames.bind(Styles);

const MeetupComponent = () => {
  const user = useSelector(({ userPreferencesReducer }) => (
    userPreferencesReducer.user
  ));
  const searchBarActive = useSelector(({ searchReducers }) => (
    searchReducers.searchBarActive
  ));

  return (
    <div className={cx(Styles['meetup-wrapper'], searchBarActive && Styles.blurred)}>
      { searchBarActive && <div className={Styles.overlay} /> }
      <MeetupTopSection />
      <MeetupUpcomingEventsSection user={user} />
      <MeetupSocialSection />
      <MeetupVisitSection />
      <MeetupFormSection />
      <MeetupPreviousSection />
    </div>
  );
};

export default withComponentName(withMetatag(MeetupComponent, 'meetup'));
